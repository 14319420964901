<template>
  <div class="index">

    <el-row class="top">
      <el-col :span="17" style=" border:1px solid #eee;height:240px;box-sizing: border-box;">

        <el-row style=" background: #f5f5f5;padding:15px 10px">
          <el-col :span="8" class="account  ">
            <div class="img" @click="$router.push('/member-setting')">
              <img v-if="!member.id" src="../../../assets/images/my.jpg" />
              <img v-else-if="!member.avatar" src="../../../assets/images/my.jpg" />
              <img v-else :src="$config.baseUrl+member.avatar" />

            </div>
            <div class="name">
              <p>
                <i class="icon icon-member" v-if="member.type == 1"></i>
                <i class="icon icon-enterprise" v-else></i>
                {{ $store.state.member.name }}
              </p>
              <dl>

                <el-tag type="warning" v-if="member.type==1">个人用户</el-tag>
                <el-tag type="warning" v-else>企业用户</el-tag>
                <el-tag class='font' type="danger">1v</el-tag>
              </dl>
            </div>
          </el-col>

          <el-col :span="16">
            <el-row class="daka">
              <el-col :span="16"><i class="el-icon-coin"></i>
                <b> 仪商积分：</b><i style="color:red;font-size:16px"> {{member.score}}</i>分
              </el-col>
              <el-col :span="8">
                <p class=" " @click="getSign()">今日签到 </p>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="6" class="item" style="display: flex; ">

                <el-dropdown>
                  <span @click="$router.push('/member-address')">
                    收货地址
                  </span>

                </el-dropdown>

              </el-col>
              <el-col :span="6" class="item">

                <el-dropdown>
                  <span class="el-dropdown-link">
                    优惠券<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">

                    <el-dropdown-item>
                      <p @click="$router.push({path:'/member-coupon',query:{id:'0'}})">店铺优惠券 <span class="themefont"> {{couponTotal}}</span> </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <p @click="$router.push({path:'/member-coupon',query:{id:'1'}})">平台优惠券 <span class="themefont">0 </span> </p>
                    </el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown>

              </el-col>
              <el-col :span="6" class="item">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    我的收藏<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <p @click="$router.push({path:'/member-collect',query:{id:'0'}})">商品 <span class="themefont">{{collectTotal}} </span> </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <p @click="$router.push({path:'/member-collect',query:{id:'1'}})">店铺 <span class="themefont">{{collectShopTotal}} </span> </p>
                    </el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown>

              </el-col>
              <el-col :span="4" class="item">
                <el-dropdown>
                  <span class="el-dropdown-link" @click="$router.push('/member-invoice')">
                    发票设置
                  </span>

                </el-dropdown>

              </el-col>
            </el-row>

          </el-col>

        </el-row>
        <div>

        </div>
        <div class=" top_list ">
          <p>
            我的订单
          </p>
          <el-row>
            <el-col :span="6">
              <dl @click="$router.push({path:'member-order',query:{number:1}})">
                <dt class="num">{{notPay}}</dt>
                <dd>待付款</dd>
              </dl>
            </el-col>
            <el-col :span="6">
              <dl @click="$router.push({path:'member-order',query:{number:2}})" class="hover">
                <dt class="num" @click="toCollect"> {{ notSend }}</dt>
                <dd>待发货</dd>
              </dl>
            </el-col>
            <el-col :span="6">
              <dl @click="$router.push({path:'member-order',query:{number:3}})" class="hover">
                <dt class="num" @click="toFollow">{{ notGet }}</dt>
                <dd>待收货</dd>
              </dl>
            </el-col>
            <el-col :span="6">
              <dl @click="$router.push({path:'member-order',query:{number:4}})" class="hover">
                <dt class="num" @click="toIntegral">{{ notEvaluate }}</dt>
                <dd>待评价</dd>
              </dl>
            </el-col>
            <!-- <el-col :span="4">
            <dl>
              <dt
                class="num"
                @click="toIntegral"
              >{{ refundTotal }}</dt>
              <dd>退款</dd>
            </dl>
          </el-col> -->
          </el-row>
        </div>

      </el-col>
      <el-col :span="7">
        <div class="infomation">
          <div class="nav"><span> 消息提醒</span></div>

          <ul class="cont">
            <li v-for="(item, index) in information" :key="index">
              <dl>
                <dt><b> {{ item.message_title }}</b></dt>
                <dd style="color:#999;font-size:12px;margin-top:5px">{{item.message_body}}</dd>
              </dl>
              <p style="text-align:right">{{ item.creat_time }}</p>
            </li>
          </ul>

        </div>

      </el-col>
    </el-row>

    <div class="indent">
      <div class="Logistics">
        <ul class="flow">

          <div class="hst">
            <h3>热卖商品</h3>
            <ul>
              <li class="item" v-for="(item, index) in goodList" :key="index" @click="$util.toDetail(item.get_shop.shop_type, item.goods_id )"><img :src="$config.baseUrl + (item.get_pic.files_path)"
                  alt="">
                <div class="item-info">
                  <p class="item-name">
                    {{ item.goods_name }}
                  </p>

                  <div class="item-price" v-if="item.isprice == 2">
                    <span>￥{{ parseFloat(item.min_price).toFixed(2) }}</span>
                    -
                    <span>￥{{ parseFloat(item.max_price).toFixed(2) }}</span>
                  </div>

                  <div class="item-price" v-if="item.isonly == 1 && item.isprice == 1">

                    <span>￥{{
                        parseFloat(item.sales_price/ 100).toFixed(2) 
                      }}</span>
                  </div>
                  <div class="item-price" v-if="item.isonly == 2 && item.isprice == 1">
                    <!-- <span>￥{{ parseFloat( (item.spec_json[0] || {}).price).toFixed(2)}}
                    起</span> -->

                    <span>￥{{ item.spec_min_price}}
                      起</span>
                  </div>
                </div>
              </li>

            </ul>
          </div>

        </ul>
      </div>
    </div>

  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      goodList: [],
      notGet: 0,
      notPay: 0,
      notSend: 0,
      information: [],
      member_type: "",
      activeName: "second",
      account: localStorage.getItem("account"),
      // orderList: '',
      tableData: [],
      multipleSelection: [],
      arr2: [],
      arr3: [],
      arr4: [],
      total: 0,
      // currentPage: 0,
      page: 1, //初始页
      totalCount: 1,
      pageSizes: [10],
      limit: 10,
      baseUrl: this.$config.baseUrl,
      SumNum: 0,
      dataList: "",

      photo: "",
      shopId: null,
      couponTotal: 0,
      collectTotal: 0,
      collectShopTotal: 0,
      integralTotal: 0,
      refundTotal:0,
      notEvaluate: 0,

      sign: "签到领积分",

      servelist: [],

      allList: []
    };
  },
  computed: {
    member() {
      return this.$store.state.member;
    }
  },
  mounted() {
    this.getOrderCount();
    this.getCoupon();
    this.getCollect();

    this.getInformation();

    this.getCollectShop();
    this.getGoods();
  },
  methods: {
    //签到
    getSign() {
      if (!this.member.id) {
        this.$message("请先登录");
        return;
      }
      if (!(this.sign == "今日已签到")) {
        this.$post("home/sign", { member_id: this.member.id }).then(res => {
          console.log(res);

          this.sign = "今日已签到";
          this.$message.success("签到成功，积分" + res.addScoreTotal);
        });
      } else {
        this.$message.warning("您今日已签到");
      }
    },

    getGoods() {
      //获取热卖商品

      //获取热卖商品

      this.$get("home/goods", { goods_label: 2 }).then(res => {

        this.goodList = res.items.data;
      });
    },
    getOrderCount() {
      this.$post("home/user/orderCount", { member_id: this.member.id }).then(
        res => {
          console.log("orderCount", res);
          this.notPay = res.findResult0; //待付款
          this.notGet = res.findResult3; //待收货
          this.notEvaluate = res.findResult5; //已完成待评价
          this.notSend = res.findResult13; //待发货
        }
      );
    },

    end() {
      clearTimeout(this.loop); //清空定时器，防止重复注册定时器
    },
    //获取信息
    getInformation() {
      this.$get("home/message", { member_id: this.member.id }).then(res => {
        console.log("res", res);
        this.information = res.items.data;
      });
    },

    //搜藏
    toCollect() {
      this.$router.push("/member-collect");
    },
    //跳转优惠券
    toCoupon() {
      this.$router.push("/member-coupon");
    },

    //跳转关注
    toFollow() {
      this.$router.push("/member-collect");
    },
    //跳转积分
    toIntegral() {
      this.$router.push("/member-integral");
    },

    //获取优惠券数量
    getCoupon() {
      var total1;
      var total2;
      //获取平台惠券的数量
      this.$get("home/coupon_receive", {
        member_id: this.member.id
      }).then(res => {
        // console.log("res1", res);
        total1 = res.total;
        //获取店铺惠券的数量
        this.$get("home/shop_coupon_receive", {
          member_id: this.member.id
        }).then(res => {
          // console.log("res2", res);
          total2 = res.total;
          //总和
          this.couponTotal = 0;
          this.couponTotal = total1 + total2;
        });
      });
    },

    // 获取收藏商品数量
    getCollect() {
      this.$get("home/collect", { member_id: this.member.id }).then(res => {
        this.collectTotal = 0;
        this.collectTotal = res.total;
      });
    },

    // 获取收藏店铺数量
    getCollectShop() {
      this.$get("home/collectshop", { member_id: this.member.id }).then(res => {
        this.collectShopTotal = 0;
        this.collectShopTotal = res.total;
      });
    },


    //确认收货
    ConfirmReceipt(item) { },
    //评价
    evaluate(row) {
      this.$router.push({
        path: "/comments",
        query: {
          orderData: JSON.stringify(row)
        }
      });
    },
    handleSizeChange(val) {
      // 改变每页显示的条数
      this.limit = val;
      // 点击每页显示的条数时，显示第一页
      this.getData(val, 1);
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.page = 1;
    },
    // 显示第几页
    handleCurrentChange(val) {
      // 改变默认的页数
      this.page = val;
      // 切换页码时，要获取每页显示的条数
      this.getData(this.limit, val * this.limit);
    },
    tableRowClassName(row, rowIndex) { },
    //删除订单
    Delarr(item, index) {
      this.tableData[index].get_order_product = [];
    },

    handleClick(tab, event) { },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";
.daka {
  padding-top: 10px;
  .el-icon-coin {
    font-size: 22px;
    color: #f98911;
    vertical-align: middle;
    margin-left: 40px;
  }
  p {
    width: 106px;
    height: 26px;
    line-height: 26px;
    text-indent: 40px;
    font-weight: bold;
    background: url("../../../assets/images/daka.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.infomation {
  width: 100%;
  border: 1px solid #eee;
  height: 240px;
  box-sizing: border-box;
  color: #666;
  .cont {
    height: 190px;
    overflow-y: scroll;
    li {
      padding: 5px 10px;
    }
  }

  margin: 0 10px;
  .nav {
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    border-bottom: 1px solid #eee;
  }
}
.top {
  .top_list {
    p {
      text-align: left;
      padding-left: 20px;
      line-height: 35px;
    }
    text-align: center;
    .el-col {
      text-align: center;
      dl {
        margin: 0 auto;
        dt {
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;
        }
        dd {
          text-align: center;
          line-height: 40px;
        }
      }
    }

    .el-col {
      @include row;
      @include align-center;
    }
  }
  .item {
    @include row;
    @include align-center;
    padding-top: 15px;
    box-sizing: border-box;
    cursor: pointer;
    .el-dropdown {
      text-align: center;
      margin: 0 auto;
    }
  }
  .account {
    @include row;
    @include align-center;
    .img {
      position: relative;
      margin-right: 20px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      .font {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 18px;
        line-height: 18px;

        text-align: center;
        display: block;
      }
    }
    .name {
      display: grid;
      text-align: center;
      font-weight: bold;
      dl {
        width: 100%;
        @include space-between;
        .el-tag {
          height: 25px;
          line-height: 25px;
          margin-left: 5px;
        }
      }
      p {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
}
.bg {
  background: #f5f5f5;
  padding-top: 20px;
}
.member {
  display: flex;
  justify-content: space-between;
  width: 1226px;
  margin: 0 auto;
}
.Center_home_page {
  .evaluate-fa {
    height: 30px;
    margin: 10px 0 15px 0;
  }

  .evaluate {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .evaluate:hover {
    color: #f40;
    border-color: #f40;
  }
}

.list {
  @include center;
  box-shadow: 0 1px 6px #999;

  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
  display: flex;
  padding: 15px;
  .integral::after {
    clear: both;
    display: block;
    content: "";
  }

  ul {
    height: 100%;
    background: #f5f5f5;
  }
  .right_image {
    float: left;
    width: 300px;
    height: 200px;
    padding: 15px 0 0 50px;

    li {
      text-align: center;

      img {
        width: 150px;
        height: 150px;
      }
    }

    // li:nth-child(2){
    //   margin-left: 40px;
    // }
  }
}

.Integral {
  @include center;
  box-shadow: 0 1px 6px #999;
  height: 190px;
  width: 520px;
  float: left;
  margin-left: 45px;
  justify-content: space-between;
  display: flex;
  .integral::after {
    clear: both;
    display: block;
    content: "";
  }
  .integral {
    ul {
      float: left;
      width: 180px;
      height: 200px;
      padding: 40px 0 0 50px;

      li {
        height: 80px;
      }
    }
  }

  .right_image {
    float: left;
    width: 300px;
    height: 200px;
    padding: 15px 0 0 50px;

    li {
      text-align: center;

      img {
        width: 150px;
        height: 150px;
      }
    }

    // li:nth-child(2){
    //   margin-left: 40px;
    // }
  }
}

.indent {
  min-height: 500px;
  height: auto !important;
  overflow: hidden;
  margin-bottom: 30px;

  // height:500px;
  .Logistics {
    // border-bottom: 1px solid #ccc;
    .flow {
      li {
        float: left;
        width: 20%;
        text-align: center;
        cursor: pointer;

        i {
          font-size: 40px;
        }

        i:hover {
          color: #e94c15;
        }
      }
    }
  }

  .Od {
    // height: 400px;
    width: 100%;
    overflow: hidden;
    // background: yellow;
  }
}

.hst {
  margin-top: 35px;
  height: 500px;
  width: 100%;
  // background-color:yellow;
  margin-top: 20px;

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  ul {
    box-sizing: border-box;
    margin: 0 -8px;
    height: 100%;
    li {
      margin: 0 8px;
      margin-bottom: 10px;
      display: inline-block;
      width: 175px !important;
      height: 230px;
      text-align: center;
      padding: 12px;
      border: 1px solid #e6e6e6;

      img {
        height: 140px;
        width: 100%;
      }

      .item-info {
        @include wrap-between;

        width: 100%;
        .item-name {
          text-align: left;
          padding: 5px 0;
          font-size: 14px;
          color: #757575;
          line-height: 18px;
          overflow: hidden;
          height: 43px;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-price {
          width: 100%;
          line-height: 20px;
          text-align: left;
          span {
            color: red;
            font-size: 14px;
            text-align: left;
          }
        }
      }
    }
  }
}

.recommend_ggods {
  height: 300px;
  width: 100%;
  // background-color:yellow;
  margin-top: 70px;

  h3 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  ul {
    width: 100%;

    li {
      width: 250px !important;
      height: 280px;
      text-align: center;
      padding-top: 25px;
      border: 1px solid #e6e6e6;
      float: left;

      img {
        height: 200px;
        width: 248px;
      }

      p {
        margin-top: 10px;
        font-size: 18px;
      }
    }

    li:nth-child(n + 2) {
      margin-left: 30px;
    }
  }
}
</style>
<style lang="scss">
.waning-row {
  float: left !important;
  padding: 0 !important;
  // margin: 10px 0 !important;
  width: 100% !important;
  // border: 1px solid #ccc !important;
}
.el-table .waning-row td {
  border: 1px solid #ccc;
}

.warning-row .el-table_1_column_1 {
  border-right: 1px solid #ccc !important;
  width: 44px !important;
}

.warning-row .el-table_1_column_2 .cell {
  width: 352px;
  padding: 10px;
}

.warning-row .el-table_1_column_3 {
  width: 127px !important;
  padding: 10px !important;
}

.el-table .warning-row .el-table_1_column_4 {
  width: 55px !important;
  padding: 10px !important;
  border: 1px solid #ccc;
}

.warning-row .el-table_1_column_5 {
  width: 118px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

.warning-row .el-table_1_column_6 {
  width: 130px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

.warning-row .el-table_1_column_7 {
  width: 120px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc;
}

.warning-row .el-table_1_column_8 {
  width: 132px !important;
  padding: 10px !important;
  border-right: 1px solid #ccc !important;
}
// .el-table__body-wrapper ttr{
//   border: 1px solid #ccc;
// }
</style>
